<template>
  <div class="agent-hero">
    <div class="agent-hero-content">
      <h3>邀請您成為合夥人</h3>
      <div class="mt-1">
        <p>邀请粉絲，粉絲首單立享 <b>20%OFF</b></p>
        <p>粉絲下單，合夥人最高返現 <b>50%OFF</b></p>
      </div>
      <button class="btn primary btn-lg mt-1" @click="$emit('start')">我要申請合夥人</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'agent-hero',
  data: () => ({
  }),
  computed: {},
  created () {},
  methods: {}
}
</script>

<style type="text/scss" lang="scss" scoped>
.agent-hero {
  position: relative;
  background: {
    image: url(../../../static/images/agent-landing/banner.png);
    size: contain;
    repeat: no-repeat;
    position: right;
  };
  &:before {
    content: '';
    display: block;
    padding-bottom: 42.2%;
  }
  &-content {
    position: absolute;
    top: 10vw;
    left: 12vw;
    h3 {
      font-size: 48px;
    }
    p {
      font-size: 22px;
    }
  }
  @media (max-width: 992px) {
    &-content {
      h3 {
        font-size: 36px;
      }
      p{
        font-size: 17px;
      }
    }
  }
  @media (max-width: 768px) {
    background: {
      image: url(../../../static/images/agent-landing/mobile-banner.png);
    };
    &:before {
      padding-bottom: 133.8%;
    }
    &-content {
      left: 0;
      width: 100%;
      text-align: center;
      h3 {
        font-size: 32px;
      }
      p{
        font-size: 16px;
      }
    }
  }
}
</style>
