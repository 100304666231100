<template>
  <section class="home-product" :class="{'with-shadow': showTitle}">
    <header v-show="showTitle">
      <h2>
        我們的產品
      </h2>
    </header>
    <slot></slot>
    <div :class="[{'with-bg': showTitle}, showTitle ? 'container' : 'container-fluid']">
      <div class="row">
        <div class="col-sm-4 mt-1" v-for="(item, index) in products" :key="index">
          <router-link :to="`/products?type=${item.type}&template=${item.name}`">
            <div class="home-product__item">
              <div class="home-product__item--banner" :class="item.name">
                <div class="holder"></div>
              </div>
              <div class="home-product__item--intro">
                <h2 class="item-title">{{item.title}}</h2>
                <p>{{item.desc}}</p>
                <a class="text-primary" href="javascript:;">
                  了解詳情
                  <i class="fa fa-chevron-right"></i>
                </a>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeProduct',
  props: {
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    products: [
      {
        name: 'diarybook',
        title: '时光书',
        desc: '印出臉書,專屬於你的紙質書',
        type: 'diarybook'
      },
      {
        name: 'magazine',
        title: '横款海量照片书',
        desc: '用照片珍藏回憶',
        type: 'photobook'
      },
      {
        name: 'album',
        title: 'A4輕奢雜誌冊',
        desc: '性價比首選',
        type: 'photobook'
      }
    ]
  }),
  computed: {},
  created () {}
}
</script>

<style type="text/scss" lang="scss" scoped>
@import '../../styles/variables';
.home-product {
  position: relative;
  &>.container {
    position: relative;
    &.with-bg {
      &:before, &:after {
        content: '';
        display: block;
        height: 100%;
        width: 25%;
        background: {
          repeat: no-repeat;
          size: contain;
          position: 0 0;
          image: url(../../../static/images/home/dot-bg.png);
        }
        top: -5%;
        left: -2%;
        position: absolute;
        z-index: -1;
      }
      &:after {
        top: auto;
        left: auto;
        right: 0;
        bottom: -5%;
        background-position: 100% 100%;
        opacity: .8;
      }
    }
  }
  &.with-shadow {
    .home-product__item {
      box-shadow: 0 12px 40px 0 rgba(175, 175, 175, 0.2);
    }
  }
  &__item {
    max-width: 500px;
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    &--banner {
      background: {
        repeat: no-repeat;
        image: url(../../../static/images/home/product-diarybook.png);
        size: cover;
        position: center;
      };
      &.magazine {
        background-image: url(../../../static/images/home/product-photobook.png);
      }
      &.album {
        background-image: url(../../../static/images/album.jpg);
      }
      &.calendar {
        background-image: url(../../../static/images/product/swipe-calendar-v.jpg);
      }
      .holder {
        width: 100%;
        padding-bottom: 65%;
      }
    }
    &--intro {
      padding: 0 0 1.5rem;
      min-width: 12em;
      font-size: 14px;
      h2 {
        margin-bottom: .75rem;
      }
      p {
        margin-bottom: .5rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .home-product {
    &__item {
      display: flex;
      &--banner {
        width: 100%;
      }
      &--intro {
        text-align: left;
        padding: 1.25rem;
        line-height: 1.25;
      }
    }
  }
}
</style>
